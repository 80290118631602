<template>
  <main class="register-reseller w-100 blank-aside-js" :class = "(isOpenAside=='true')?'blank-aside':''">
    <section class="register-reseller__numbers">
      <span class="register-reseller__number register-reseller__number_active">1</span>
      <span class="register-reseller__number register-reseller__number_active">2</span>
      <span class="register-reseller__number register-reseller__number_active">3</span>
      <span class="register-reseller__number">4</span>
    </section>
    <h1 class="title title__h1 register-reseller__title">{{ $t("Marketer Registration: Security and Payment Details") }}</h1>
    <p class="text register-reseller__text">{{ $t("Please enter the following details") }}</p>
    <section class="reseller-safe register-reseller__reseller-safe">
      <form class="reseller-safe__container">
        <div class="reseller-safe__cover reseller-safe__cover-1">
          <div class="reseller-safe__wrapper">
            <div class="reseller-safe__ip">
              <input class="reseller-safe__checkbox" type="checkbox" id="ip" />
              <label class="label reseller-safe__checkbox-label" for="ip">IP {{ $t("Secure") }}</label>
            </div>

            <div class="reseller-safe__device">
              <input class="reseller-safe__checkbox" type="checkbox" id="device" />
              <label class="label reseller-safe__checkbox-label" for="device">{{ $t("Secure device") }}</label>
            </div>
          </div>

          <div class="reseller-safe__pay-method">
            <label class="label reseller-safe__label">{{ $t("Payment method") }}</label>
            <div class="reseller-safe__dropdown">
              <select class="reseller-safe__dropdown-select">
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
                <option value="4">Four</option>
              </select>
              <button class="button reseller-safe__dropdown-button"></button>
              <div class="reseller-safe__dropdown-list">
                <a class="link reseller-safe__dropdown-option" href="#" data-value="1">One</a>
                <a class="link reseller-safe__dropdown-option" href="#" data-value="2">Two</a>
                <a class="link reseller-safe__dropdown-option" href="#" data-value="3">Three</a>
                <a class="link reseller-safe__dropdown-option" href="#" data-value="4">Four</a>
              </div>
            </div>
          </div>

          <div class="reseller-safe__credit">
            <label class="label reseller-safe__label">{{ $t("Line of credit") }}</label>
            <input class="input reseller-safe__input" type="text" />
          </div>
        </div>

        <div class="reseller-safe__cover reseller-safe__cover-2">
          <p class="text reseller-safe__bank-text">{{ $t("Bank account information") }}</p>

          <div class="reseller-safe__bank">
            <div class="reseller-safe__bank-name reseller-safe__bank-item">
              <label class="label reseller-safe__label">{{ $t("Bank") }}</label>
              <input class="input reseller-safe__input" type="text" />
            </div>
            <div class="reseller-safe__bank-number reseller-safe__bank-item">
              <label class="label reseller-safe__label">{{ $t("Bank number") }}</label>
              <input class="input reseller-safe__input" type="text" />
            </div>
            <div class="reseller-safe__bank-account reseller-safe__bank-item">
              <label class="label reseller-safe__label">{{ $t("Account Number") }}</label>
              <input class="input reseller-safe__input" type="text" />
            </div>
            <div class="reseller-safe__bank-branch reseller-safe__bank-item">
              <label class="label reseller-safe__label">סניף</label>
              <input class="input reseller-safe__input" type="text" />
            </div>
          </div>
        </div>

        <div class="reseller-safe__days">
          <p class="text reseller-safe__days-text">{{ $t("Collection days") }}:</p>
          <div class="reseller-safe__days-wrapper">
            <span class="reseller-safe__day">{{ $t("A") }}</span>
            <span class="reseller-safe__day reseller-safe__day_active">{{ $t("B") }}</span>
            <span class="reseller-safe__day">{{ $t("C") }}</span>
            <span class="reseller-safe__day">{{ $t("D") }}</span>
            <span class="reseller-safe__day reseller-safe__day_active">{{ $t("E") }}</span>
            <span class="reseller-safe__day">{{ $t("F") }}</span>
          </div>
        </div>

        <div class="reseller-safe__comment">
          <label class="reseller-safe__label reseller-safe__comment-label">{{ $t("Remarks") }}</label>
          <textarea class="reseller-safe__textarea"></textarea>
        </div>
      </form>

      <a class="link reseller-safe__link" href="register-reseller-4.html">{{ $t("Continue") }}</a>
    </section>
  </main>
</template>

<script>
export default {
  name: "RegisterReseller3",
};
</script>
<style lang="scss" scoped>
.ltr-type {
  .reseller-safe__ip {
    margin-right: unset;
    margin-left: 30px;
  }
  .reseller-safe__device {
    margin-right: unset;
    margin-left: 78px;
  }
  .reseller-safe__textarea {
    background-color: #ffffff;
  }
}

</style>